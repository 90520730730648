
body {
  background-color: black;
  width:100%;
  height: 100%;

}

html {
  background-color: black;
  width:100%;
  height: 100%;
}

.App {
  text-align: center;
  width: 100%;
  height:100%;
  /*margin-top: 50px;*/

}

.AppContainer {
    background-image: url('./splashImage2.jpg');
    background-position: center top;
    background-size: 100% auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.imageContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%
}

.informationContainer {
  position: fixed;
  top: 0;
  left: 30%;
  width: 70%;
  height: 100%;
  overflow:auto;
  padding: 10%;
}

@media only screen and (max-width: 767px) {
  .AppContainer {
    background-image: url('./splashImage2.jpg');
    background-position: center top;
    background-size: 100% auto;
    position: static;
    width: 100%;
    height: 100%;
  }
  .imageContainer {
    position: static;
    display: none;
    padding-top: 10px;
    /*margin: auto;*/
    width: 50%;
    border-radius: 15px;
  }

  .informationContainer {
    margin: auto;
    position: static;
    width: 100%;
  }

}



.idleForestContainer {
  width: 100%;
}

.idleForestLogo {
  width: 100%;
  box-shadow: 0px 10px 5px #0d6722;
  border-radius: 5px;
}

.myImage {
  position: relative;
  width: 90%;
  top: 10%;
  margin-left: 5%;
}

.idleForestButton {
  opacity: 0.5;
  margin-top: 5px;
}

.idleForestButton:hover {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

.btn-success:hover {
  opacity: 0.7 !important;
  background-color: #44964a !important;
}


@-moz-keyframes spin {
  25% { -moz-transform: rotate3d(0,-1,0,-45deg); };
  75% { -moz-transform: rotate3d(0,-1,0,45deg); };
}

@-webkit-keyframes spin {
  25% { -moz-transform: rotate3d(0,-1,0,-45deg); };
  75% { -moz-transform: rotate3d(0,-1,0,45deg); };
}

@keyframes spin {
  25% { -webkit-transform: rotate3d(0,-1,0,-45deg); transform:rotate3d(0,-1,0,-45deg); }
  75% { -webkit-transform: rotate3d(0,-1,0, 45deg); transform:rotate3d(0,-1,0, 45deg); }
}

.btn-success {
  background-color: #1b6514 !important;
}


.infoContainer {
  margin-bottom: 50px;
  display: grid;
  grid-column-gap: auto(200px, 2fr);

}

.basicInfo {
  padding-top: 50px;
  box-shadow: 10px 10px 5px grey;

}

.skillsInfo {
  padding-top: 10px;
  box-shadow: 10px 10px 5px grey;

}

.basicInfoCard {
   box-shadow: 10px 10px 5px grey;

}

  
.cardContainer {
  box-shadow: 10px 10px 5px grey;

}

@keyframes rightSlide {
  0%   {left:500px; top:0px;}
  100% {left:0px; top:0px;}
}

@keyframes leftSlide {
  0%   {right: 500px; top:0px;}
  100% {right: 0px; top:0px;}
}



/* The element to apply the animation to */
.leftCardContainer {
  animation-name: rightSlide;
  animation-duration: 2s;
  border-radius: 5px;
    text-align: left;

   background-color: rgba(0, 0, 0, 0.5) !important;
  color: white !important

}

.leftQuoteClip {
  padding-bottom: 75px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 87%, 50% 75%, 0% 75%);


}

.rightCardContainer {
  animation-name: leftSlide;
  animation-duration: 2s;
  border-radius: 5px;
  text-align: left;
   background-color: rgba(0, 0, 0, 0.5) !important;
  color: white !important

}

.rightQuoteClip {
   padding-bottom:100px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 50% 75%, 25% 87%, 25% 75%, 0% 75%);
}



.idleForestNavLink {
    color: white;
    font-family: "comic"

}

.idleForestNavLink:hover {
    opacity: 0.4;
    background-color: white !important;
    color:white !important;
}

.resume {
  width: 100%;
  height: 500px;
}

#nav-dropdown {
  color: grey;
}

#nav-dropdown:hover {
  color: lightgrey;
}

.dropdown-menu.show {
  background-color: black;
  color: white;
}
.dropdown-item {
  color: white !important;
}

a.idleForestNavLink.dropdown-item:hover {
  color: black !important;
}

.white {
  color: #26c505;
}

.font30 {
  font-size: 30px;  
}




